console.log("loading sheetmusic.js");
//const ABCJS = require("packs/abcjs_basic_6.0.0-beta.33-min.js");
//const ABCJS = require("packs/abcjs_basic_6.0.0-beta.33.js");
//const ABCJS = require("packs/abcjs_basic_6.0.0-beta.34-dev.js");
const ABCJS = require("packs/abcjs_basic_6.0.0-beta.36-min.js");

function CursorControl() {
  var self = this;

  self.onReady = function() {
    var downloadLink = document.querySelector(".download");
    downloadLink.addEventListener("click", download);
    downloadLink.setAttribute("style", "");
    var clickEl = document.querySelector(".click-explanation")
    clickEl.setAttribute("style", "");
  };
  self.onStart = function() {
    var svg = document.querySelector("#sheetmusic svg");
    var cursor = document.createElementNS("http://www.w3.org/2000/svg", "line");
    cursor.setAttribute("class", "abcjs-cursor");
    cursor.setAttributeNS(null, 'x1', 0);
    cursor.setAttributeNS(null, 'y1', 0);
    cursor.setAttributeNS(null, 'x2', 0);
    cursor.setAttributeNS(null, 'y2', 0);
    svg.appendChild(cursor);

  };
  self.beatSubdivisions = 2;
  self.onBeat = function(beatNumber, totalBeats, totalTime) {
    if (!self.beatDiv)
      self.beatDiv = document.querySelector(".beat");
    self.beatDiv.innerText = "Beat: " + beatNumber + " Total: " + totalBeats + " Total time: " + totalTime;
  };
  self.onEvent = function(ev) {
    if (ev.measureStart && ev.left === null)
      return; // this was the second part of a tie across a measure line. Just ignore it.

    var lastSelection = document.querySelectorAll("#sheetmusic svg .highlight");
    for (var k = 0; k < lastSelection.length; k++)
      lastSelection[k].classList.remove("highlight");

    var el = document.querySelector(".feedback").innerHTML = "<div class='label'>Current Note:</div>" + JSON.stringify(ev, null, 4);
    for (var i = 0; i < ev.elements.length; i++ ) {
      var note = ev.elements[i];
      for (var j = 0; j < note.length; j++) {
	note[j].classList.add("highlight");
      }
    }

    var cursor = document.querySelector("#sheetmusic svg .abcjs-cursor");
    if (cursor) {
      cursor.setAttribute("x1", ev.left - 2);
      cursor.setAttribute("x2", ev.left - 2);
      cursor.setAttribute("y1", ev.top);
      cursor.setAttribute("y2", ev.top + ev.height);
    }
  };
  self.onFinished = function() {
    var els = document.querySelectorAll("svg .highlight");
    for (var i = 0; i < els.length; i++ ) {
      els[i].classList.remove("highlight");
    }
    var cursor = document.querySelector("#sheetmusic svg .abcjs-cursor");
    if (cursor) {
      cursor.setAttribute("x1", 0);
      cursor.setAttribute("x2", 0);
      cursor.setAttribute("y1", 0);
      cursor.setAttribute("y2", 0);
    }
  };
}

var cursorControl = new CursorControl();

var synthControl;

function clickListener(abcElem) {
  var output = "currentTrackMilliseconds: " + abcElem.currentTrackMilliseconds + "<br>" +
      "midiPitches: " + JSON.stringify(abcElem.midiPitches, null, 4) + "<br>" +
      "gracenotes: " + JSON.stringify(abcElem.gracenotes, null, 4) + "<br>" +
      "midiGraceNotePitches: " + JSON.stringify(abcElem.midiGraceNotePitches, null, 4) + "<br>";
  document.querySelector(".clicked-info").innerHTML = "<div class='label'>Clicked info:</div>" +output;

  var lastClicked = abcElem.midiPitches;
  if (!lastClicked)
    return;

  ABCJS.synth.playEvent(lastClicked,
			abcElem.midiGraceNotePitches,
			synthControl.visualObj.millisecondsPerMeasure()
		       ).then(function (response) {
    console.log("note played");
  }).catch(function (error) {
    console.log("error playing note", error);
  });
}

var abcOptions = {
  add_classes: true,
  clickListener: self.clickListener,
  responsive: "resize",
  generate_warnings: true,
  warnings_id: "warnings"
};

function smload() {
  document.querySelector(".start").addEventListener("click", start);

  if (ABCJS.synth.supportsAudio()) {
    synthControl = new ABCJS.synth.SynthController();
    synthControl.load("#sm-audio", cursorControl, {displayLoop: true, displayRestart: true, displayPlay: true, displayProgress: true, displayWarp: true});
  } else {
    document.querySelector("#sm-audio").innerHTML = "<div class='audio-error'>Audio is not supported in this browser.</div>";
  }
  setTune(false);
}

function download() {
  if (synthControl)
    synthControl.download("download.wav");
}

function start() {
  if (synthControl)
    synthControl.play();
}

function setTune(userAction) {
  var visualObj = ABCJS.renderAbc("sheetmusic",
				  $("#sheetmusic").html(),
				  abcOptions)[0];
  var midi = ABCJS.synth.getMidiFile($("#sheetmusic").html());
  var midiButton = document.querySelector(".midi");
  midiButton.innerHTML = midi;

  // TODO-PER: This will allow the callback function to have access to timing info - this should be incorporated into the render at some point.
  var midiBuffer = new ABCJS.synth.CreateSynth();
  midiBuffer.init({
    //audioContext: new AudioContext(),
    visualObj: visualObj,
    // sequence: [],
    // millisecondsPerMeasure: 1000,
    // debugCallback: function(message) { console.log(message) },
    options: {
      // soundFontUrl: "https://paulrosen.github.io/midi-js-soundfonts/FluidR3_GM/" ,
      // sequenceCallback: function(noteMapTracks, callbackContext) { return noteMapTracks; },
      // callbackContext: this,
      // onEnded: function(callbackContext),
      // pan: [ -0.5, 0.5 ]
    }
  }).then(function (response) {
    console.log(response);
    if (synthControl) {
      synthControl.setTune(visualObj, userAction).then(function (response) {
	console.log("Audio successfully loaded.")
      }).catch(function (error) {
	console.warn("Audio problem:", error);
      });
    }
  }).catch(function (error) {
    console.warn("Audio problem:", error);
  });
}

$(document).on("turbolinks:load", function(){
  console.log('another load')
})

$(document).on("load", function(){
  console.log('first load')
})

function start_sheetmusic() {
  var tgt = $("#sheetmusic")
  console.log('checking for sheetmusic: ' + tgt.length)
  if(tgt.length > 0) {
    console.log('render sheetmusic')
    console.log(tgt.html())
    smload();
  }
}
$(document).on("turbolinks:load", function(){
  console.log('tl:load start_sheetmusic');
  start_sheetmusic();
})
$(window).on("load", function(){
  console.log('load start_sheetmusic');
  start_sheetmusic();
})

console.log("sheetmusic.js loaded");
