console.log("player.js loading")
$(document).on('shown.bs.modal', '#login-modal', function(event) {
  window.modal_event_source = $(event.relatedTarget);
  window.modal_event_source_href = $(event.relatedTarget).attr('href');
  console.log('modal on')
  console.log(window.modal_event_source_href)
})

$(document).on("turbolinks:load", function(){
  $(".audio-player-preview-button").click(function(event) {
    $(this).hide();
    var ac = $(this).parent(".audio-player-preview").children(".audio-controls")
    var url = ac.attr('data-url')
    $(ac).removeClass("d-none");
    var src = "<source src='" + url + "' data-retry=0></source>"
    $(ac).children("audio").append(src)
    event.stopPropagation();
  })
  $(".tolocaltime").each(function(idx){
    var dt = $(this).text()
    var localDate = new Date(dt)
    var ary = localDate.toLocaleString().split(', ')
    str = ary[0] + "<small>" + ary[1] + "</small>"
    //$(this).text(str)
    $(this).html('<time datetime="' + dt+ '" class="timestampx">' +
			str + '</time>')
  })
  $(".trestle-table").find('time.timestamp').each(function(idx){
    var dt = $(this).attr('datetime')
    var localDate = new Date($(this).attr('datetime'))
    var ary = localDate.toLocaleString().split(', ')
    str = dt.substring(0, 10) + "<small>" + ary[1] + "</small>"
    $(this).html(str)
  })
  $('.auto-form input:not(.do-not-submit)').on('change', function(event){
    console.log("changed " + this.name + " " + this.value)
    jQuery.rails.fire($('.auto-form')[0], 'submit')
  })
  $('#sharing-modal').on('shown.bs.modal', function (e) {
    // render the Facebook share button
    FB.XFBML.parse();
  })
  $('#popup-test').on('click', function(){
    console.log('test click')
    let href = $(this).attr('href')
    let features = 'left=100,top=100,width=600,height=600,toolbar=yes,' +
	'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
    let zfeatures = 'width=600,height=600'
    let w = open('https://twitter.com/intent/tweet?&text=Mechanical%20Tune', '', zfeatures)
    if (!w) {
      console.log('open failed')
    }
    return !w;
  })
  $('#fb-share').on('click', function(){
    var link = $(this).attr('href')
    var name = $('meta[property="og:title"]').attr('content')
    console.log("sharing " + name + " at " + link)
    // source for media
    console.log('Facebook click')
    FB.ui({
      method: 'feed',
      link: link,
      name: name,
      picture: $('meta[property="og:image"]').attr('content'),
      caption: name,
      dscription: 'Mechanical Tunes created this song just for me!',
      hashtag: '#mechtunes'
    })
  })
})

class Player {
  constructor(obj) {
    this.player = $(obj)
    this.player_elem = $(this.player[0]);
    this.player_elem_raw = this.player_elem[0]
    this.history = [];
    this.current = null;
    this.future = [];
    this.sotd = this.player_elem.hasClass("sotd");
    this.terminated = false;
    this.loading = false;
    this.play_pressed = false;
    this.player_id = "P" + Math.floor(Math.random() * 1000000)
    this.request_seq = 0
    this.tech_diff = $(".technical-difficulties")
    this.messages = [
      "Tunes written and played just for you!",
      "Streaming bespoke music made while you listen!",
      "Custom composed tunes streaming now!",
      "New tunes every minute of the day!",
      "Never the same song twice, unless you like it!",
      "Like a song and we'll make more like it!",
      "Make videos with your favorite songs!"
    ]
    var pobj = this;
    $("#playlist-nav-backward").on("click", function(){pobj.back()})
    $("#playlist-nav-forward").on("click", function(){pobj.nav_forward()})
    $('.press-play-notice').hide()
    this.player.on("canplay.player",
	     function(){$('.press-play-notice').show()})
    this.player.on("playing",
		   function(){pobj.on_playing()})
    this.player.on("play.player",
		   function(){pobj.on_play()})
    this.player.on("loadedmetadata",
		   function(){pobj.on_loadedmetadata(this)})
    //this.player.on("play.player",
    //function(){pobj.on_play()})
    this.player.on("ended",
		   function(){pobj.on_ended()})
    console.log('flavor-range elems')
    $('.flavor-range').each(function(index){
      console.log("flavor-range "+ this.name + " " + this.value)
    })
    $('.flavor-range').on('change', function(event){
      console.log("changed " + this.name + " " + this.value)
      jQuery.rails.fire($('.flavors-form')[0], 'submit')
    })
    setTimeout(function(){
      if (!pobj.terminated) {
	pobj.cycle_message();
	setTimeout(function(){
	  pobj.cycle_message();
	}, 8000 * (1 + Math.random()))
      }
    }, 8000 * (1 + Math.random()))

    this.technical_difficulties_hide()
    this.button_sync();
    this.load_first();
    this.log_events = true;
    this.log_calls = true;
  }

  log_event(name, extra='no extra') {
    if (this.log_events) {
      console.log(">>> " + name)
      if (extra != 'no extra')
	console.log(extra)
    }
  }

  log_call(name, extra='no extra') {
    if (this.log_calls) {
      console.log("*** " + name)
      if (extra != 'no extra')
	console.log(extra)
    }
  }

  on_loadedmetadata(tgt){
    console.log("loaded metadata")
    this.current_duration = tgt.duration
    console.log("elem duration " + tgt.duration + " seconds")
  }

  on_play() {
    this.log_event('play')
    $('.press-play-notice').addClass("d-none");
    this.technical_difficulties_hide()
    this.player.off("canplay.player")
    this.player.off("play.player")
    this.play_pressed = true
  }

  on_playing() {
    this.log_event('playing')
    this.technical_difficulties_hide()
    this.playing_title = this.current.title
    this.playing_mp3path = this.current.mp3path.split('/').pop()
    if (this.future.length == 0) {
      ms = (this.player_elem.duration / 10 + 0.1) * 1000
      ms = Math.random() * 1000
      console.log("loading new in " + ms + "ms")
      console.log("loading new from play.player")
      tobj = this
      setTimeout(function(){tobj.load_new()}, ms)
    } else {
      console.log("on deck: " + this.future[0].title)
    }
  }

  on_ended() {
    this.log_event('ended')
    if (this.ended_title != this.playing_title) {
      dataLayer.push({
	event: "audio",
	audioPlayerAction: "finished",
	audioTitle: this.playing_title,
	audioUrl: this.playing_mp3path
      })
      this.ended_title = this.playing_title
    }
    this.playing_title = null
    this.playing_mp3path = null
    this.nav_forward();
  }

  shutdown() {
    this.log_call('shutdown')
    this.terminated = true;
    this.history = [];
    this.current = null;
    this.future = [];
    this.pause();
  }

  technical_difficulties_hide() {
    td = $('.technical-difficulties')
    td.addClass('d-none');
    td.parents('#player-messages').addClass('d-none')
  }

  cycle_message() {
    var idx = Math.floor(this.messages.length * Math.random());
    $('.cycle-message').text(this.messages[idx]);
  }

  play() {
    this.log_call('play')
    this.playing = true;
    this.player_elem_raw.play();
    this.technical_difficulties_hide();
  }

  pause() {
    this.log_call('pause')
    this.player_elem_raw.pause();
  }

  back() {
    this.log_call('back')
    if (this.history.length > 0) {
      if (this.current) {
	this.future.push(this.current);
      }
      this.current = this.history.pop()

      this.pause();
      this.render();
      this.button_sync();
    }
  }

  nav_forward() {
    if (self.terminated)
      return;
    this.log_call('nav_forward')
    this.pause();
    if (this.future.length == 0) {
      console.log("loading new from nav_forward")
      this.load_new();
    } else {
      this.forward();
    }
  }

  forward() {
    if (self.terminated)
      return;
    this.log_call('forward')
    if (this.future.length > 0) {
      if (this.current != null) {
	this.history.push(this.current)
	this.current = null
      }
      this.current = this.future.pop()
      this.render();
      this.button_sync();
    } else {
      console.log("nothing to play")
      this.technical_difficulties("Looking for something to play")
      this.load_new()
    }
  }

  loading_message() {
    $("#playlist-current").html("Loading custom tune for you!")
    $("#playlist-current-subtitle").html(window.spinner_image)
  }

  clear() {
    this.player_elem.empty();
  }

  update_feedback(label, comment) {
    var values = this.history[this.history.length - 1];
    var fb = values["feedback"];
    fb["label"] = label;
    fb["comment"] = comment;
    this.render_feedback(fb);
  }

  block_load() {
    if (this.loading) {
      if ((new Date()) - this.loading < 120 * 1000) {
	console.log('another load in progress since ' + this.loading)
	return true
      }
      this.loading = false
    }
    return false
  }

  load_new(td_msg=null) {
    if (td_msg) {
      this.technical_difficulties(td_msg)
    }
    this.log_call("load_new")
    if (this.block_load())
      return
    path = "/player/playlist_async.json";
    if (this.sotd)
      path += '?sotd=true'
    console.log("*** load_new " + path)
    this.retries = 0
    this.loading = new Date()
    this.load_async(path)
  }

  load_first(td_msg=null) {
    if (td_msg) {
      this.technical_difficulties(td_msg)
    }
    this.log_call("load_first")
    if (this.block_load())
      return
    tobj = this
    this.wait_until_ready(function(){
      path = "/player/playlist_async.json?";
      if (tobj.sotd)
	path += 'sotd=true&'
      var preload = $(".player-audio").attr("data-preload")
      if (preload) {
	path += 'preload=' + preload + '&'
	$(".player-audio").removeAttr("data-preload")
      }
      this.retries = 0
      this.loading = new Date()
      tobj.load_async(path + "first=true")
    }, -1)
  }

  render_last_feedback() {
    this.render_feedback(this.current["feedback"]);
  }

  render_feedback(fb) {
    if (!fb) return;
    console.log('render_feedback: ' );
    console.log(fb);
    $(".feedback-choice a").each(function(index){
      ary = $(this).attr('href').split("?")
      $(this).attr('href', fb["path"] + "?" + ary[1])
      cmp = "label=" + fb["label"];
      if (cmp == ary[1]) {
	$(this).parent().addClass('feedback-choice-active')
      } else {
	$(this).parent().removeClass('feedback-choice-active')
      }
    })
  }

  facebook_link(url) {
    var d = $('<div class="fb-share-button"></div>')
    d.attr("data-layout", "button")
    d.attr("data-size", "large")
    d.attr("data-href", url)
    var a = $('<a target="_blank" id="fb-link" ' +
	      'class="fb-xfbml-parse-ignore>Share</a>')
    a.attr('href',"https://www.facebook.com/sharer/" +
	   "sharer.php?src=sdkpreparse&u=" + encodeURIComponent(url))
    d.append(a)
    return d
  }

  twitter_link(url) {
    console.log('start twitter link')
    $('.twitter-share-button').remove()
    var src = $('#twitter-src')
    var clone = src.clone()
    clone.attr('data-url', this.current['share_url'])
    var msg = src.attr('data-text')
    clone.removeAttr('id')
    clone.removeAttr('style')
    clone.removeAttr('xclass')
    clone.attr('data-text', msg + ' ' + this.current['title'])
    clone.attr('class', src.attr('xclass'))
    src.after(clone)
    console.log(clone)
    clone.attr('href',
	       encodeURI(src.attr('href') +
			 '&text=' + clone.attr('data-text') +
			 '&url=' + clone.attr('data-url') +
			 '&hashtags=' + clone.attr('data-hashtags') +
			 '&via=' + clone.attr('data-via')))
    clone.on('click', function(){
      let href = $(this).attr('href')
      console.log(href)
      let features = "width=600,height=600"
      let zfeatures = 'width=600,height=600'
      //let w = window.open('https://twitter.com/intent/tweet?&text=Mechanical%20Tune', '', zfeatures)
      let w = window.open(href, '', features)
      if (w) {
	w.focus()
      }
      return !w
    })
    console.log('finished twitter link')
    //twttr.widgets.load();
    //$.getScript("http://platform.twitter.com/widgets.js");
  }

  render() {
    this.log_call("render")
    $("#playlist-current").html(this.current['title'])
    $("#playlist-current-subtitle").html(this.current['subtitle'])
    //$("#share-tune .fb-share-button").attr('data-href',
    //					   this.current['share_url'])
    //$("#share-tune-fb").html(this.facebook_link(this.current['share_url']))
    $(".share-link").text(this.current['share_url'])
    $(".share-title").text(this.current['title'])
    $(".share-subtitle").text(this.current['subtitle'])
    $(".share-subtitle-ns").text(this.current['subtitle'].replace(/\s/g, ''))
    $('meta[property="og:url"]').attr('content', this.current['share_url'])
    $('meta[property="og:title"]').attr('content', this.current['title'])
    $('#fb-share').attr('href', this.current['share_url'])
    this.twitter_link(this.current['share_url'])
    document.title = this.current['title']

    //FB.XFBML.parse();
    this.render_feedback(this.current["feedback"]);
    console.log("rendering");
    console.log(this.current);
    this.set_source(this.current['mp3path'])
    this.technical_difficulties_hide();
    $('[data-toggle="tooltip"]').tooltip()
  }

  set_source(url) {
    this.log_call("set_source to " + url)
    var src = "<source src='" + url + "' data-retry=0></source>"
    this.clear()
    this.player_elem.append(src)
    this.player_elem_raw.load()
    tobj = this
    setTimeout(function(){tobj.play()}, 2000)
    this.player_elem.children('source').on('error', function failed(e) {
      tobj.wait_until_ready(function(){
	tobj.check_existance(url)
      })
    })
  }

  check_existance(url) {
    this.log_call("check existance " + url)
    if (self.terminated) {
      console.log('url check due to termination')
      return;
    }
    tobj=this
    fetch(url + '?exists').
      then(response=>{
	console.log('check_existance: ' + response.status)
	if (response.status == 200) {
	  rtext = response.text()
	  console.log('check_existance: ' + rtext)
	  if (rtext == 'exists') {
	    console.log('check_existance: set source')
	    tobj.set_source(url)
	  }
	  else {
	    console.log('check_existance: forward')
	    tobj.forward()
	  }
	} else {
	  wait_until_read(function(){
	  tobj.check_existance(tgt)
	  })
	}
      }).
      catch(error=>{
	console.log('check_existance error, try again')
	console.log(error)
	tobj.wait_until_ready(function(){
	  tobj.check_existance(tgt)
	})
      });
  }

  technical_difficulties(msg, show=true) {
    this.log_call("technical_difficulties " + msg)
    td = $(".technical-difficulties")
    td = this.tech_diff
    td.text(msg)
    if (show) {
      td.removeClass('d-none');
      td.parents('#player-messages').removeClass('d-none');
    }
  }

  wait_until_ready(callback, retries=0) {
    this.log_call("wait_until_ready " + retries)

    if (self.terminated) {
      console.log('abandon wait due to termination')
      return;
    }
    if (retries == 1) {
      msg = 'We are experiencing technical difficulties, please stand by.'
      this.technical_difficulties(msg)
    }
    tobj = this;
    delay = ((2 * retries) + Math.random() + 1) * 1000
    setTimeout(function(){
      fetch('/ready').
	then(response=>{
	  console.log('fetch')
	  if (response.status == 200) {
	    tobj.technical_difficulties('Servers spinning back up...',
					false)
	    callback()
	  }
	  else {
	    if (response.status == 502) {
	      tobj.technical_difficulties('Upgrade in progress, ' +
                                          'servers restarting soon...')
	    }
	    console.log("can't reach server: " + retries +
			"   (" + response.status + ")")
	    tobj.wait_until_ready(callback, retries + 1)
	  }
	}).
	catch(error=>{
	  console.log("wait_until_ready " + retries)
	  var msgs = [
	    'Installing wires on new poles...',
	    'Telegraph poles down, engineers enroute...',
	    'Requestioning new shiny wires...'
	  ]
	  tobj.technical_difficulties(msgs[retries % 3])
	  console.log("can't reach server: " + retries +
		      "   fetch error " + error)
	  tobj.wait_until_ready(callback, retries + 1)
	})
	  }, delay)
  }

  playlist_timeout() {
    if (this.sotd) {
      return 120
    }
    else {
      return 120
    }
  }

  process_load(values) {
    this.log_call("process_load", values)
    if (values.error) {
      if(values.error.includes('no such token')) {
	if (this.future.length == 0) {
	  td_msg = 'They forgot about us, asking for new tune'
	  console.log(path)
	  this.loading = false
	  this.load_first(td_msg);
	}
	return;
      }
      if (values.error.includes('no performance')) {
	if (this.future.length == 0) {
	  td_msg = 'Composer broke a pencil, trying another'
	  console.log(path)
	  this.loading = false
	  this.load_first(td_msg);
	}
	return
      }
      console.log('did not recognize error')
      return
    }
    if (values.status == 'pending') {
      if (this.playlist_timeout() < values.elapsed_seconds) {
	if (this.future.length == 0) {
	  console.log("giving up, too long")
	  td_msg = 'Composer and orchestra took a long break, trying again.'
	  this.loading = false
	  this.load_first(td_msg);
	}
	return
      }

      new_path = path
      retry_wait = 1000 * (1 + values.elapsed_seconds / 10)
      if (!path.includes('token')) {
	new_path += (path.includes('?') ? "&" : "?") +
	  "token=" +
	  values.token
      }
      if (this.retries == 0) {
	if (this.current_duration)
	  retry_wait = 3 * (this.current_duration) * 1000 / 4
	else
	  retry_wait = 20 * 1000
      } else {
	if (this.current_duration &&
	    values.elapsed_seconds < 3 * this.current_duration / 4)
	  retry_wait = (3 * this.current_duration / 4 -
			values.elapsed_seconds) * 1000
      }
      var today = new Date();
      var time = today.getHours() + ":" +
	  today.getMinutes() + ":" + today.getSeconds();
      console.log("retry " + this.retries)
      console.log("time is " + time)
      this.retries += 1
      //if (values.elapsed_seconds < 6) {
      //retry_wait = 1000 * (3 + Math.random())
      //}
      console.log("current duration " + this.current_duration + " seconds")
      console.log("elapsed " + values.elapsed_seconds + " seconds")
      console.log("retry in " + (retry_wait / 1000) + " seconds")
      console.log("new path " + new_path)
      tobj = this
      setTimeout(function(){
      	tobj.load_async(new_path)
      }, retry_wait)
      return
    }
    if (values.title) {
      console.log("bingo!")
      this.future.unshift(values)
      this.button_sync();
      console.log("playing: " + this.playing_title)
      this.loading = false
      if (!this.playing_title) {
	console.log("nothing current, move forward")
	this.forward()
      }
      if (this.future.length < 3) {
	console.log('only ' + this.future.length +
		    ' in the queue, adding one more')
	this.load_new()
      }
      return
    }
    console.log('did not recognize values')
  }

  load_async(path) {
    this.log_call("load_async " + path)

    var xhttp = new XMLHttpRequest()
    var tobj = this
    xhttp.path = path
    xhttp.onreadystatechange = function(){
      if (this.readyState == 4) {
	console.log('ready state change: readyState: '+this.readyState,
		    '  status: '+this.status)
	if (tobj.terminated) {
	  console.log('ignoring response due to termination')
	}
	else if (this.status == 200) {
	  tobj.process_load(JSON.parse(this.responseText))
	} else if (this.status >= 400 || this.status == 0) {
	  console.log('retry load when ready, status:' + this.status)
	  console.log(this)
	  if (this.status == 404) {
	    console.log("no resouce: " + this.path)
	    tobj.wait_until_ready(function(){
	      this.loading = false
	      tobj.load_first("No piano roll in the box. Asking for another")
	    })
	  }
	  else {
	    tobj.path = this.path
	    console.log('wait_until_ready says ok')
	    tobj.wait_until_ready(function(){
	      this.loading = false
	      tobj.load_first()
	    })
	  }
	} else {
	  tobj.loading = false
	  console.log("unexpected status: " + this.status)
	  console.log(this)
	  tobj.wait_until_ready(function(){
	    console.log('wait_until_ready says ok (unex)')
	    tobj.load_async(this.path)
	  })
	}
      }
    }
    xhttp.open("GET", path)
    xhttp.ontimeout = function(e) {
      console.log("get playlist timed out")
      tobj.technical_difficulties('Servers are very, very busy at the moment...')
    }
    /*xhttp.addEventListener('error', function(e) {
      console.log("get playlist error")
      console.log(e)
    })*/
    xhttp.timeout = 120000
    xhttp.send()
  }

  button_sync() {
    $("#playlist-nav-backward").prop('disabled', this.history.length == 0);
    $("#playlist-nav-forward").prop('disabled', this.future.length == 0);
  }
}

$(document).on("turbolinks:before-render", function() {
  console.log('***** before-render *****')
  if (window.player) {
    console.log('shutdown player')
    window.player.shutdown()
    window.player = null
  }
})

$(document).on("beforeunload", function() {
  console.log('***** beforeunload *****')
  if (window.player) {
    console.log('shutdown player')
    window.player.shutdown()
    window.player = null
  }
})

$(document).on("turbolinks:load", function(){
  console.log('tl:load player');
  if ($(".player-audio").length > 0) {
    window.player = new Player(".player-audio")
  }
})
console.log("player.js loaded")
