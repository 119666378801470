function fill_membership_modal() {
  $(document).on('shown.bs.modal', '#membership-modal', function(event) {
    const price_ref = $(event.relatedTarget).attr('data-price-ref')
    console.log(price_ref)
    $(".membership-msg").addClass('d-none')
    var tgt;
    if (price_ref == "Free") { tgt = $(".membership-free") }
    else if (price_ref == "Signup") { tgt = $(".membership-signup") }
    else {tgt = $(".membership-payment") }
    tgt.removeClass('d-none')
    console.log("data-signed-in: " + $(event.relatedTarget).attr('data-signed-in'))
    if ($(event.relatedTarget).attr('data-signed-in') == "true") {
      $('#membership-modal .signup-or-login').addClass('d-none')
      $(".stripe-checkout").removeAttr('disabled')
    } else {
      $('#membership-modal .signup-or-login').removeClass('d-none')
      $(".stripe-checkout").attr('disabled','')
    }
    $(".stripe-checkout").attr('data-price-ref', price_ref)
  })
}
fill_membership_modal()

console.log('membership.js loaded')

var createCheckoutSession = function(priceId) {
  return fetch("/checkout/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      priceId: priceId
    })
  }).then(function(result) {
    return result.json();
  });
};

window.setup_stripe = function(public_key) {
  if (typeof window.stripe == "undefined") {
    console.log('set up stripe')
    window.stripe = window.Stripe(public_key)
  }
}

$(document).on('click', '.stripe-checkout', function(event) {

  const priceId = $(event.currentTarget).attr('data-price-ref')

  createCheckoutSession(priceId).then(function(data){
    console.log(data.sessionId)
    window.stripe.redirectToCheckout({
      sessionId: data.sessionId
    }).then(handleResult)
  })
})
