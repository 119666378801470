require('croppie')
$(document).on("turbolinks:load", function(){
  split_pitch = function(t) {
    let s = t.length
    let o = t.substr(s - 1)
    let pc = t.substr(0, s - 1)
    return [parseInt(o), pc]
  }

  if (0 < $('.htr-piano').length) {
    var htr_piano = $('.htr-piano')
    let min_pitch = htr_piano.attr('data-min-pitch')
    let max_pitch = htr_piano.attr('data-max-pitch')
    if (min_pitch) {
      let p_id = $("#Piano ." + min_pitch).attr('id')
      console.log("found " + p_id)
      n = parseInt(p_id.substring(1))
      console.log("  n = " + n)
      for(let i = 0; i <= n; i++) {
	$("#Piano #P" + i).addClass('exclude')
      }
    }
    if (max_pitch) {
      let p_id = $("#Piano ." + max_pitch).attr('id')
      console.log("found " + p_id)
      n = parseInt(p_id.substring(1))
      console.log("  n = " + n)
      for(let i = n; i <= 95; i++) {
	$("#Piano #P" + i).addClass('exclude')
      }
    }
    let exclude = htr_piano.attr('data-exclude')
    if (exclude) {
      let re = /[A-G]b?[0-9]/g
      let excluded_pitches = exclude.match(re)
      excluded_pitches.forEach(function(p){
	$("#Piano ." + p).addClass('exclude')
      })
    }
  }

  console.log('setting up cropping')

  var cropit = null

  if (0 < $('.cropit').length) {
    //var tgt = $('.cropit')
    //cropit = tgt.croppie(options)

    // upload image for cropping

    $('img.avatar-editable').on('click', function(){
      console.log('start editing')
      if (cropit) {
	cropit.croppie('destroy')
	$('input.cropit-input').remove()
      }
      let area = $('.cropit-area')
      let input = $('<input class="form-control cropit-input do-not-submit"'+
		      ' type="file" name="cropit-flle" id="cropit-file">')
      area.append(input)
      //area.append($('<input type="hidden" name="profile[avatar]" id="profile_avatar">'))
      let options = {
	  enableExif: true,
	  enableOritentation: true,
	  viewport: {
	    width: 100,
	    height: 100,
	    type: 'circle'
	  },
	  boundary: {
	    width: 300,
	    height: 300,
	  }
      }
      cropit = $('.cropit').croppie(options)

      var fileinput = $('.cropit-input')
      console.log(fileinput[0].outerHTML)
      fileinput.on('change', function(){
	var reader = new FileReader();
	console.log('loading new image')
	reader.onload = function(e) {
	  console.log('reader.onload')
	  $('.avatar-editable').addClass('d-none')
	  $('.cropit').removeClass('d-none')
	  $('.cropit-buttons').removeClass('d-none')
	  cropit.croppie('bind', {
	    url: e.target.result
	    //points: [0, 0, 300, 300],
	    //zoom: true
	  })
	  console.log('new image loaded')
	}
	reader.readAsDataURL(this.files[0])
      })
    }) // end of click on avatar-editable

    $('.cropit-cancel').on('click', function(){
      $('input.cropit-input').remove()
      $('.cropit-buttons').addClass('d-none')
      $('.cropit').addClass('d-none')
      $('.avatar-editable').removeClass('d-none')
      cropit.croppie('destroy')
      cropit = null
    })

    $('.cropit-upload').on('click', function(){
      cropit.croppie('result', {
	type: 'canvas',
	size: 'viewport',
	circle: true
      }).then(function(image){
	$('#profile_avatar').attr('value', image)
	$('.avatar-editable').attr('src', image)
	$('.avatar').attr('src', image)
	$('input.cropit-input').remove()
	$('.cropit-buttons').addClass('d-none')
	$('.cropit').addClass('d-none')
	$('.avatar-editable').removeClass('d-none')
	jQuery.rails.fire($('.auto-form')[0], 'submit')
      })
    })
  }
})
