import CodeMirror from "codemirror/lib/codemirror.js"
import "codemirror/mode/yaml/yaml.js"
function start_code_editor() {
  console.log('code_editor checking')
  var tgt = $(".code-editor")
  if(tgt.length > 0) {
    console.log("code_editor creating")
    var editor = CodeMirror.fromTextArea(tgt[0],
					 {mode:'yaml', tabsize: 2})
  }
}
window.start_code_editor = start_code_editor
$(document).on("load", function(){
  start_code_editor();
})
$(document).on("ready", function(){
  start_code_editor();
})
$(document).on("turbolinks:render", function(){
  console.log("turbolinks:render");
})
$(document).on("turbolinks:before-render", function(){
  console.log("turbolinks:before-render");
})
$(document).on("turbolinks:visit", function(){
  console.log("turbolinks:visit");
})
$(document).on("turbolinks:click", function(){
  console.log("turbolinks:click");
})
$(document).on("turbolinks:load", function(){
  console.log('starting code_editor after tl:load');
  start_code_editor();
})
$(document).on("turbolinks:render", function(){
  console.log('starting code_editor after tl:render');
  //start_code_editor();
})
