// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//import JQuery from 'jquery'
//window.$ = window.JQuery = JQuery

import $ from 'jquery'
//if (location.pathname.indexOf("/admin/") == 0) {
//  console.log("skipping bootstrap")
//} else {
  console.log("loading bootstrap")
  import("bootstrap")
      var bootstrap_enabled = (typeof $().modal == 'function');
      if (bootstrap_enabled) {
	console.log('bootstrap enabled in application.js')
      } else {
	console.log('bootstrap not enabled in application.js')
      }
//}

if (window._rails_loaded) {
  console.log("skipping rails/ujs  already loaded")
} else {
  console.log("loading rails/ujs")
  require("@rails/ujs").start()
}

require("turbolinks").start()
//import('turbolinks').then(t=>t.start())
require("@rails/activestorage").start()
require("channels")

//require('jquery/src/jquery');
//require('popper.js/dist/popper')
console.log(location.pathname)
import "packs/sheetmusic";
import "packs/hearingtest2";
//import player_start from "packs/player";
import "packs/player";
import "../src/application.scss";
import "packs/code_editor";
import "packs/membership";
import "packs/cropping";
import "packs/artifacts";
import "packs/performances";
//player_start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//console.log("============================")
