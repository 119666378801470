console.log("loading sheetmusic.js");
//const ABCJS = require("packs/abcjs_basic_6.0.0-beta.33-min.js");
//const ABCJS = require("packs/abcjs_basic_6.0.0-beta.33.js");
const ABCJS = require("packs/abcjs_basic_6.0.0-beta.34-dev.js");

var ht_synthControl;

class OctaveTest {
  tab_id_from = function(suffix) { return 'tab-' + suffix }
  test_id_from = function(suffix) { return 'test-' + suffix }
  label_id_from = function(suffix) { return 'test-label-' + suffix }
  pane_id_from = function(suffix) { return 'pane-test-' + suffix }
  tones_id_from = function(suffix) { return 'tones-' + suffix }
  hear_class_from = function(suffix) { return 'hear-' + suffix }

  constructor(abc_elem){
    this.abc_id = $(abc_elem).attr('id')
    this.abc_elem = abc_elem
    this.suffix = this.abc_id.split('-')[1].replace(',', '').replace("'", '')
    this.octave = this.suffix[0]
    console.log("*** octave test for " + this.suffix)
    this.audio_id = "ot-audio-" + this.suffix
    this.start_id = "ot-start-" + this.suffix
    this.next_id = "ot-next-" + this.suffix
    this.hear_class = this.hear_class_from(this.suffix)

    // external element ids
    this.tab_id = this.tab_id_from(this.suffix)
    this.test_id = this.test_id_from(this.suffix)
    this.label_id = this.label_id_from(this.suffix)
    this.pane_id = this.pane_id_from(this.suffix)
    this.tones_id = this.tones_id_from(this.suffix)

    this.add_radio_buttons(abc_elem, this.suffix)
    abc_elem.after("<button id='" + this.next_id +
		   "' class='btn btn-dark d-none'>Next</button>")
    abc_elem.after("<span class='divider'>&nbsp;</span>")
    abc_elem.after("<button id='" + this.start_id +
		   "' class='btn btn-dark'>Start</button>")
    abc_elem.after("<div id='" + this.audio_id +
		   "' class='ot-audio' display='none'></div>")
    let options = { ...OctaveTest.abc_options }
    let notes = abc_elem.attr('data-notes').
	replaceAll(',', '').replaceAll("'", '').length
    options.staffwidth = notes * 33
    this.visual_object =
      ABCJS.renderAbc(this.abc_id,
		      this.abc_elem.html(),
		      options)[0]
    this.setup3(abc_elem)

    /*
      this.build_subtests(abc_elem.attr('data-notes'),
			abc_elem.attr('data-clef'),
			abc_elem.attr('data-duration'))*/
    var self = this
    $(".hear-" + this.suffix).on('click', function(){
      $("#test-label-" + self.suffix).removeClass('in-progress')
      $("#" + self.next_id).removeClass('d-none')
    })
    $("#" + this.next_id).on('click', function(){
      self.next_action(this)
    })
  }

  next_action = function(elem) {
    tgt = $('.test-label.in-progress')
    console.log('next clicked: in-progress ' + tgt.length)
    if (tgt[0]) {
      //var in_progress_id = $(tgt[0]).attr('id')
      //var next_idx = in_progress_id.split('-').slice(-1)
      //$('#ot-test-' + next_idx).click()
      p = $(tgt[0]).parent()
      console.log("next tab:")
      console.log(p[0].outerHTML)
      console.log($(p.attr('href')).length)
      p.tab('show')
      console.log('show sent')
      return
    }

    var bad_notes = []
    var before_size = Object.keys(OctaveTest.all).length
    var self = this
    $(".hear:checked").each(function(index){
      var value = $(this).attr('value')
      var parts = value.split('-')
      if (parts[0] == 'some')
	OctaveTest.all[parts[1]].build_subtests()
      else {
	if (parts[0] == 'none') {
	  let notes = $(this).parents('.ot-test-row').
	      find('.ot-tones').attr('data-notes')
	  bad_notes.push(self.notes_to_std(notes))
	}
      }
    })
    if (Object.keys(OctaveTest.all).length == before_size) {
      console.log('next modal popup')
      let modal = $("#ht-results-modal")
      if (bad_notes.length > 0) {
	let results = modal.find('.ht-results-notes')
	let value = bad_notes.join(' ')
	results.text(value)
	$('.ht-results-fail').removeClass('d-none')
	$('.ht-results-notes').removeClass('d-none')
	$('input#exclude').attr('value', value)
      } else {
	$('.ht-results-pass').removeClass('d-none')
	$('input#exclude').attr('value', 'pass')
      }
      modal.modal('show')
    } else {
      console.log(elem.outerHTML)
      elem.click()
    }
  }

  note_to_std = function(str) {
    var pitch = str[0]
    var len = str.length - 1
    if (pitch == '_') {
      pitch = str[1] + 'b'
      len -= 1
    }
    if (len == 0)
      return pitch + '4'
    if (str[str.length - 1] == ',')
      return pitch + (4 - len)
    if (str[str.length - 1] == "'")
      return pitch + (4 + len)
    console.warn('unexpected note structure: ' + str)
  }

  notes_to_std = function(str) {
    return str.split(' ').map(x => this.note_to_std(x)).join(' ')
  }

  radio_button = function(kind, suffix) {
    var input = $('<input class="form-check-input hear" type="radio">')
    input.addClass(this.hear_class)
    var id = 'hear-' + kind + '-' + suffix
    input.attr('name', 'hear-' + suffix)
    input.attr('id', id)
    input.attr('value', kind + '-' + suffix)
    var label = $('<label class="form-check-label"></label>')
    label.attr('for', id)
    if (kind == 'none') {
      label.text("Hear no notes")
    }
    else {
      label.text("Hear " + kind + " notes")
    }
    var div = $('<div class="form-check form-check-inline"></div>')
    div.append(input)
    div.append(label)
    return(div)
  }

  add_radio_buttons = function(abc_elem, suffix) {
    abc_elem.parent().before(this.radio_button('all', suffix))
    abc_elem.parent().before(this.radio_button('some', suffix))
    abc_elem.parent().before(this.radio_button('none', suffix))
  }

  static abc_mod = {
    0: ",,,,",
    1: ",,,",
    2: ",,",
    3: ",",
    4: "",
    5: "'",
    6: "''",
    7: "'''",
    8: "''''"
  }

  build_test_content = function(notes, clef, duration, suffix) {
    var pane = $('#' + this.pane_id).clone()
    pane.attr('id', this.pane_id_from(suffix))
    pane.removeClass('show')
    pane.removeClass('active')
    var body = $(pane).find('.row div.text-center')
    body.empty()
    pane.children('.row').attr('id', this.test_id_from(suffix))
    var tones = $('<div class="ot-tones"></div>')
    var sep = duration
    var abc = notes.join(sep) + sep
    tones.text(clef + "\n" + abc)
    tones.attr('id', this.tones_id_from(suffix))
    tones.attr('data-clef', clef)
    tones.attr('data-notes', notes.join(' '))
    tones.attr('data-duration', duration)
    var holder = $('<div class="ot-tones-holder"></div>')
    holder.append(tones)
    body.append(holder)
    $('#test-content').append(pane)
    return tones
  }

  build_test_tab = function(suffix) {
    var container = $('#test-tabs')
    var label_li = $('#' + this.tab_id).parent().clone()
    var link = label_li.find('a')
    link.attr('id', this.tab_id_from(suffix))
    link.attr('href', '#' + this.pane_id_from(suffix))
    link.removeClass('active')
    var label = $(link).find('span.test-label')
    label.attr('id', this.label_id_from(suffix))
    label.text(suffix)
    label.addClass('in-progress')
    var ip = $(label_li).find('span.ot-in-progress')
    container.append(label_li)
  }

  build_test = function(notes, clef, duration, suffix) {
    var tones = this.build_test_content(notes, clef, duration, suffix)
    this.build_test_tab(suffix)
    return new OctaveTest(tones)
  }

  add_test = function(notes, clef, duration, suffix) {
    if (!OctaveTest.all.hasOwnProperty(suffix)) {
      console.log('>>>>> adding ' + suffix)
      if (duration > 8)
	duration = 8
      OctaveTest.all[suffix] =
	this.build_test(notes, clef, duration, suffix)
    }
  }

  make_suffix = function(notes) {
    return this.octave +
      (notes.join('').
       replaceAll(',', '').
       replaceAll("'", '').
       replaceAll(/_([A-G])/g, '$1b'))
  }

  build_subtests = function() {
    var abc = $('#' + this.abc_id)
    var notes = abc.attr('data-notes')
    var clef = abc.attr('data-clef')
    var duration = parseInt(abc.attr('data-duration'))
    var ary = notes.split(' ')
    console.log('build_subtests ')
    console.log(notes)
    console.log(ary)
    if (ary.length == 1)
      return
    if (ary.length < 9) {
      var midpoint = ary.length / 2
      var low = ary.slice(0,midpoint)
      var high = ary.slice(midpoint, ary.length)
      this.add_test(low, clef, 2 * duration, this.make_suffix(low))
      this.add_test(high, clef, 2 * duration, this.make_suffix(high))
    } else {
      var leftpoint = ary.length / 3
      var rightpoint = (2 * ary.length) / 3
      var low = ary.slice(0,leftpoint)
      var mid = ary.slice(leftpoint, rightpoint)
      var high = ary.slice(rightpoint, ary.length)
      this.add_test(low, clef, 2 * duration, this.make_suffix(low))
      this.add_test(mid, clef, 2 * duration, this.make_suffix(mid))
      this.add_test(high, clef, 2 * duration, this.make_suffix(high))
    }
  }

  static all = {}

  create_cursor = function() {
    var svg = document.querySelector("#" + this.abc_id + " svg");
    var cursor = document.createElementNS("http://www.w3.org/2000/svg", "line");
    cursor.setAttribute("class", "abcjs-cursor");
    cursor.setAttributeNS(null, 'x1', 0);
    cursor.setAttributeNS(null, 'y1', 0);
    cursor.setAttributeNS(null, 'x2', 0);
    cursor.setAttributeNS(null, 'y2', 0);
    svg.appendChild(cursor);
    this.cursor = cursor;
  }

  setup3 = function(abc_elem) {
    var self = this
    this.synth = new ABCJS.synth.CreateSynth()
    $('#' + this.start_id).text('Wait')
    this.running_state = -1
    this.synth.init({
      //audioContext: this.audio_context,
      visualObj: this.visual_object
    }).then(function(response){
      self.synth.prime().then(function(response){
	self.running_state = 0
	$('#' +  self.start_id).text('Start')
      })
    }).catch(function(error){
      $('' +  self.start_id).text('Error')
    })
    this.timing_callbacks =
      new ABCJS.TimingCallbacks(this.visual_object,
				{ beatCallback: function(currentBeat,
			  totalBeats,
			  lastMment,
			  position,
			  debugInfo){
				  self.beatCallback(currentBeat,
			  totalBeats,
			  lastMment,
			  position,
						   debugInfo)
				},
				  eventCallback: function(ev){
				    self.eventCallback(ev)
				  }
				})
    this.last_els = []
    this.create_cursor()
    console.log(this.cursor)
    var self = this
    $("#" + this.start_id).on('click', function(){
      self.start_stop()
    })
  };

  start_stop = function() {
    switch(this.running_state){
    case 0:  // waiting to start playing at begining
      this.synth.start()
      this.timing_callbacks.reset()
      this.timing_callbacks.start()
      $('#' + this.start_id).text('Pause')
      this.running_state = 1
      return;
    case 1:  // actively playing
      this.synth.pause()
      this.timing_callbacks.pause()
      $('#' + this.start_id).text('Play')
      this.running_state = 2
      return;
    case 2:  // paused
      this.synth.resume()
      this.timing_callbacks.start()
      $('#' + this.start_id).text('Pause')
      this.running_state = 1
      return;
    }
  };

  beatCallback = function(currentBeat,
			  totalBeats,
			  lastMment,
			  position,
			  debugInfo) {
    var x1, x2, y1, y2;
    if (currentBeat == totalBeats) {
      x1 = 0;
      x2 = 0;
      y1 = 0;
      y2 = 0;
      this.colorElements([]);
    } else {
      x1 = position.left - 2;
      x2 = position.left - 2;
      y1 = position.top;
      y2 = position.top + position.height;
    }
    /*
    this.cursor.setAttribute("x1", x1);
    this.cursor.setAttribute("x2", x2);
    this.cursor.setAttribute("y1", y1);
    this.cursor.setAttribute("y2", y2);
    */
  };

  colorElements = function(els) {
    $.each(this.last_els, function(idx, val){
      $.each(val, function(idx, elem){
	$(elem).removeClass('color')
      })
    })
    $.each(els, function(idx, val){
      $.each(val, function(idx, elem){
	$(elem).addClass('color')
      })
    })
    this.last_els = els
  };

  eventCallback = function(ev) {
    if (!ev) {
      this.is_running = !this.is_running
      this.synth.stop()
      this.timing_callbacks.stop()
      this.timing_callbacks.reset()
      $(".hear-" + this.suffix + " + .form-check-label").addClass('attention')
      $('#' + this.start_id).text('Start')
      this.running_state = 0
      this.colorElements([])
      return
    }
    this.colorElements(ev.elements)
  };

  static clickListener = function (abcElem, tuneNumber, classes, analysis, drag) {
    console.log('clicked ' + abcElem)
    var lastClicked = abcElem.midiPitches;
    if (!lastClicked)
      return;

    ABCJS.synth.playEvent(lastClicked,
			  abcElem.midiGraceNotePitches,
			  synthControl.visualObj.millisecondsPerMeasure()
			 ).then(function (response) {
			   console.log("note played");
			 }).catch(function (error) {
			   console.log("error playing note", error);
			 });
  }

  static audio_context = new AudioContext()
  static abc_options = {
    audioContext: OctaveTest.audio_context,
    add_classes: true,
    clickListener: OctaveTest.click_listener,
    //responsive: "resize",
    warnings_id: "warnings",
    staffwidth: 500,
    wrap: {
      minSpacing: 1,
      maxSpacing: 1.5
    }
  };
}

var octave_tests = {}
$(document).on("turbolinks:load", function(){
  $('.ot-tones').each(function(idx){
    ot = new OctaveTest($(this))
    OctaveTest.all[ot.octave] = ot
  })
  $('button#save').on('click', function(){
    console.log('saving test results')
    $('form#results').submit()
  })
  $('#instructions-next').on('click', function(){
    tgt = $('.test-label.in-progress')[0]
    p = $(tgt).parent()
    p.tab('show')
  })


/*
  setTimeout(function(){
    OctaveTest.all['5'].build_subtests()
  }, 3000)
  setTimeout(function(){
    OctaveTest.all['5CDE'].build_subtests()
    console.log(Object.keys(OctaveTest.all))
  }, 6000)
  *setTimeout(function(){
    OctaveTest.all['5DE'].build_subtests()
  }, 9000)
  setTimeout(function(){
    OctaveTest.all['5C'].build_subtests()
    }, 10000)*/

})
