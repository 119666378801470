window.build_video_button_state = function() {
  var images = $(".image-artifact").length
  var audio = $(".audio-artifact").length
  if (images > 0 && audio > 0)
    $(".build-video").removeAttr('disabled')
  else
    $(".build-video").attr('disabled', '')
}
window.add_tune_button_state = function() {
  var audio = $(".audio-artifact").length
  if (audio > 0) {
    $("#audio-artifact-form-builder").addClass("d-none")
    $("#audio-artifact-form-builder-button").removeClass("d-none")
  } else {
    $("#audio-artifact-form-builder").removeClass("d-none")
    $("#audio-artifact-form-builder-button").addClass("d-none")
  }
}
window.add_artifact_hover = function() {
  $(".image-artifact").hover(function(){
    $(this).find("a.btn").removeClass('d-none')
    $(this).find(".artifact-sf").removeClass('d-none')
  }, function(){
    $(this).find("a.btn").addClass('d-none')
    $(this).find(".artifact-sf").addClass('d-none')
  })
}

$(document).on("turbolinks:load", function(){
  $(".add-artifact").on("click", function(){
    $("#artifact-form").removeClass('d-none')
    $(".add-artifact").addClass('d-none')
  })
  $("#video_project_title").change(function(){
    if ($(this).val()) {
      jQuery.rails.fire($('#video-project-form')[0], 'submit')
    }
  })
  $(".performance-select").change(function(){
    jQuery.rails.fire($(this).closest('form')[0], 'submit')
  })
  window.add_artifact_hover();
  $('#audio-artifact-form-builder-button button').on('click', function(){
    $('#audio-artifact-form-builder').removeClass('d-none')
    $('#audio-artifact-form-builder-button').addClass('d-none')
  })
  $('.hs-button').on('click', function(){
    $($(this).attr('data-hide')).addClass('d-none')
    $($(this).attr('data-show')).removeClass('d-none')
  })
  $('.build-video').on('click', function(){
    $("#video-col").addClass('d-none')
    $("#spinner-col").removeClass('d-none')
  })
  window.build_video_button_state()
})
