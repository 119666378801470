
$(document).on("turbolinks:load", function(){
  if ($('#performances_table').length > 0) {
    var loadNextPage = function(){
      var next_link = $('#next_link')
      if (next_link.length == 0) { return }
      if (next_link.data("loading")){ return }  // prevent multiple loading
      var wBottom  = $(window).scrollTop() + $(window).height();
      var table = $('#performances_table')
      var elBottom = table.offset().top + table.height();
      if (wBottom > elBottom){
	next_link[0].click();
	next_link.data("loading", true);
      }
    };

    $('.pcontent').on('scroll', loadNextPage)
    window.addEventListener('scroll', loadNextPage);
    window.addEventListener('resize', loadNextPage);
    window.addEventListener('load',   loadNextPage);
  }
})
